<template>
  <div>
    <v-row class="ma-6">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="team in Teams"
        :key="team.id"
      >
        <v-card
          class="rounded-xl d-flex flex-column justify-space-between pointer"
          @click="getDetails(team)"
          elevation="4"
          height="300"
        >
          <v-img
            :src="team.Course.thumbnail"
            class="rounded-t-xl"
            height="200"
          ></v-img>

          <v-card-actions class="darkblue pa-4 white--text">
            <v-row>
              <v-col cols="12">
                <div
                  class="font-weight-bold"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'font-size: 1rem'
                      : 'font-size: 1.5rem'
                  "
                >
                  {{ team.name }}

                  <div style="font-size: 0.75rem" class="font-weight-medium">
                    {{ $t('hometeacher.hometeacher_material') }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!--DETALHES DA TURMA-->
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.mdAndUp ? '65%' : '90%'"
      :persistent="loadingVisible || loadingApplied"
    >
      <v-card class="rounded-lg">
        <v-tabs v-model="classTab" background-color="darkblue" grow dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#1"> {{ $t('teams.team_tab.tab1') }} </v-tab>
          <v-tab href="#6"> {{ $t('teams.team_atalho.atalho3') }} </v-tab>
          <v-tab href="#2"> {{ $t('teams.team_tab.tab2') }} </v-tab>
          <v-tab href="#3"> {{ $t('teams.team_tab.tab3') }} </v-tab>
          <v-tab href="#4">
            {{ $t('teams.team_tab.tab4') }}&nbsp;<span v-if="$vuetify.breakpoint.lgAndUp"> {{ $t('teams.team_tab.tab5') }}</span>
          </v-tab>
          <v-tab href="#5"> {{ $t('admin.admin_menu.menu32') }} </v-tab>
        </v-tabs>
        <v-btn
          rounded
          outlined
          @click="dialog = false"
          color='white'
          >
          <v-icon class="mr-1" color="error">mdi-close-circle</v-icon>
       </v-btn>
        <v-tabs-items :value="classTab">
          
          <!--DETALHES-->
          <v-tab-item value="1">
            <v-card-text class="my-4 my-md-6 mx-4 mx-md-8 d-flex flex-row">
              <div class="text-center">
                <v-avatar
                  :size="$vuetify.breakpoint.mdAndUp ? 260 : 200"
                  tile
                  class="rounded-xl mx-auto"
                >
                  <v-img :src="teamDetails.thumb" />
                </v-avatar>
              </div>

              <div style="font-size: 1.2rem" class="mt-4 ml-8">
                <div style="font-size: 1.6rem" class="font-weight-black">
                  {{ teamDetails.team }}
                </div>

                <div class="mt-4">
                  <strong>{{ $t('teams.team_detalhes.detalhes1') }}</strong> {{ teamDetails.course }}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes2') }}</strong> {{ teamDetails.teacher }}
                </div>
                <!-- Botão para abrir a câmera e ler o QR Code -->
                <v-btn color="primary" class="mt-4" @click="qrCodeDialog = true">
                  {{ $t('teams.team_detalhes.detalhes3') }}
                </v-btn>
                <div></div>
                <!-- Botão para acessar o material do professor -->
                <!-- <v-btn color="darkpink" style="color: white;" class="mt-4" @click="teacherTraining(3)">
                  {{ $t('teams.team_atalho.atalho3') }}
                </v-btn> -->
              </div>
            </v-card-text>
          </v-tab-item>
          
          <!--MATERIAL DO PROFESSOR-->
          <v-tab-item value="6">
            <v-card-text
              v-if="students.length !== 0"
              class="my-2"
              style="height: 75vh; overflow-y: scroll"
            >
              <v-row class="ma-6">
                <v-col cols="12">
                  <v-data-table
                    :headers="headersfiles"
                    :items="filesfolder"
                    :search="search"
                    item-key="id"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>Arquivos</v-toolbar-title>
                          <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Pesquisar aula"
                            single-line
                            hide-details
                            style="position: absolute;right: 1%;"
                          ></v-text-field>
                      </v-toolbar>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr @click="openLink(item.url)">
                        <td>{{ item.name }}</td>
                      </tr>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          
          <!--AULAS-->
          <v-tab-item value="2">
            <v-card-actions
              class="d-flex align-center mt-2"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-start'
              "
            >
              <v-btn
                small
                dark
                class="rounded-lg"
                color="darkpink"
                :loading="loadingVisible"
                @click="checkVisible()"
              >
                <div v-if="!visibleStatus">
                  <v-icon left>mdi-eye</v-icon>
                  {{ $t('botoes.botao_ativo') }}
                </div>

                <div v-if="visibleStatus">
                  <v-icon left>mdi-eye-off</v-icon>
                  {{ $t('botoes.botao_desativa') }}
                </div>
              </v-btn>
              <v-btn
                small
                dark
                class="rounded-lg"
                :class="$vuetify.breakpoint.xsOnly ? 'mt-2' : 'ml-2'"
                color="darkpink"
                :loading="loadingApplied"
                @click="checkApply()"
              >
                <div v-if="!applyStatus">
                  <v-icon left>mdi-checkbox-marked-circle</v-icon>
                  {{ $t('botoes.botao_aplicar') }}
                </div>
                <div v-if="applyStatus">
                  <v-icon left>mdi-check-circle-outline</v-icon>
                  {{ $t('botoes.botao_desaplicar') }}
                </div>
              </v-btn>
            </v-card-actions>
            <v-card-text class="my-2" style="height: 70vh; overflow-y: scroll">
            <v-col v-if="classes.length === 0" cols="12" class="mx-auto">
              <v-img
                src="../../assets/NotebookAstronaut.svg"
                max-width="25vw"
                class="mx-auto"
              />
                <div
                  class="text-center text-h6 text-md-h5 font-weight-bold mt-2"
                  style="color: #491670"
                  >
                  <span> {{ $t('alerta.alerta_noaula') }} </span>
                </div>
              </v-col>
              <v-alert
                :color="colorLookLight(index)"
                text
                dense
                outlined
                v-for="(data, index) in classes"
                :key="data.id"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-bookshelf'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="mb-3"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    sm="8"
                    lg="9"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.1rem">
                      {{ data.title }}
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ data.project }}
                    </div>
                    <div v-if="data.DateApplied" style="font-size: 0.9rem">
                      <div v-if="data.applied">
                        {{ $t('teams.team_aulas') }} {{data.DateApplied.split('-').reverse().join('/')}}
                        <v-btn text fab small @click="editdate(data)">
                          <v-icon color="darkblue">mdi-calendar-plus</v-icon>
                        </v-btn>
                      </div>   
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    lg="3"
                    class="pa-0 ma-0 d-flex"
                    :class="
                      $vuetify.breakpoint.xsOnly ? 'flex-row' : 'flex-column'
                    "
                  >
                    <v-checkbox
                      v-model="data.visible"
                      :color="colorLookDark(index)"
                      :label="
                        data.visible ? $t('label.label_disponibilizado.ativo') : $t('label.label_disponibilizado.desativo')
                      "
                      hide-details
                      class="ma-0"
                      off-icon="mdi-eye-off"
                      on-icon="mdi-eye"
                      @change="changeVisual(data)"
                      :dense="$vuetify.breakpoint.xsOnly"
                    />

                    <v-checkbox
                      v-model="data.applied"
                      :color="colorLookDark(index)"
                      :label="data.applied ? $t('label.label_aplicado.ativo') : $t('label.label_aplicado.desativo')"
                      hide-details
                      class="ma-0 mt-1"
                      on-icon="mdi-checkbox-marked-circle"
                      off-icon="mdi-checkbox-blank-circle-outline"
                      @change="changeApplied(data)"
                      :dense="$vuetify.breakpoint.xsOnly"
                    />

                    <v-checkbox
                      v-model="data.homework"
                      :color="colorLookDark(index)"
                      :label="data.homework ? $t('label.label_homework.ativo') : $t('label.label_homework.desativo')"
                      hide-details
                      class="ma-0 mt-1"
                      on-icon="mdi-checkbox-marked-circle"
                      off-icon="mdi-checkbox-blank-circle-outline"
                      @change="changeHomework(data)"
                      :dense="$vuetify.breakpoint.xsOnly"
                    />

                    <v-btn
                      :color="data.applied ? colorLookDark(index) : 'defaultColor'"
                      @click="openPhotosUploadDialog(data)"
                      class="ma-0 mt-1"
                      :dense="$vuetify.breakpoint.xsOnly"
                      :disabled="!data.applied"
                    >
                      <v-icon left>
                        mdi-file-upload
                      </v-icon>
                      {{ $t('botoes.botao_foto') }}
                    </v-btn>

                    <v-btn
                      :color="data.homework ? colorLookDark(index) : 'defaultColor'"
                      @click="openHomeworkDialog(data)"
                      class="ma-0 mt-1"
                      :dense="$vuetify.breakpoint.xsOnly"
                      :disabled="!data.homework"
                    >
                      <v-icon left>
                        mdi-clipboard-text
                      </v-icon>
                      {{ $t('hometeacher.ver_tarefas') }}
                    </v-btn>

                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
          </v-tab-item>
          
          <!--ALUNOS-->
          <v-tab-item value="3">
            <v-col v-if="students.length === 0" cols="12" class="mx-auto">
              <v-img
                src="../../assets/NotebookAstronaut.svg"
                max-width="25vw"
                class="mx-auto"
              />
                <div
                  class="text-center text-h6 text-md-h5 font-weight-bold mt-2"
                  style="color: #491670"
                  >
                  <span>{{ $t('alerta.alerta_aluno') }} </span>
                </div>
              </v-col>
            <v-card-text
              v-if="students.length !== 0"
              class="my-2"
              style="height: 75vh; overflow-y: scroll"
            >
              <v-alert
                :color="colorLookLight(index + 2)"
                text
                outlined
                v-for="(student, index) in students"
                :key="index"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-school'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="my-3"
              >
                <v-row align="center" no-gutters>
                  <!-- Coluna para informações do estudante -->
                  <v-col
                    cols="12"
                    sm="8"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.2rem">
                      {{ student.name }}
                    </div>
                    <div style="font-size: 0.9rem" class="d-flex flex-column">
                      <span>{{ student.user }}</span>
                      <span>{{ student.email }}</span>
                      <span>{{ student.phone }}</span>
                    </div>
                  </v-col>
                  <!-- Coluna para o QR Code, alinhada à direita -->
                  <v-col
                    cols="12"
                    sm="4"
                    class="d-flex justify-end"
                  >
                  <vue-qrcode :value="'https://makereducacional.com.br/login/pedagogico?studentId=' + String(student.id)"></vue-qrcode>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
          </v-tab-item>
          
          <!--MATERIAL EXTRA-->
          <v-tab-item value="4">
            <v-card-text style="height: 75vh; overflow-y: scroll">
              <v-row no-gutters>
                <v-col cols="12" md="6" v-for="(fil, ind) in files" :key="ind">
                  <v-card flat class="ma-2 white--text rounded-sm" height="100">
                    <v-alert
                      :icon="$vuetify.breakpoint.xsOnly ? false : fil.icon"
                      :prominent="!$vuetify.breakpoint.xsOnly"
                      height="100%"
                      class="
                        d-flex
                        justify-space-between
                        align-center
                        font-weight-medium
                      "
                      style="font-size: 1.2rem"
                      :color="colorLookLight(ind + 1)"
                      text
                      outlined
                    >
                      <div
                        v-if="fil.icon !== 'mdi-play-box'"
                        class="d-flex flex-column linkText"
                        @click="openURL(fil.uri)"
                      >
                        <span class="black--text font-weight-bold">
                          {{ fil.name }}
                        </span>
                        <span
                          v-if="fil.size"
                          v-text="getByteSize(fil.size)"
                          class="black--text text--secondary font-weight-medium"
                          style="font-size: 0.9rem"
                        />
                      </div>
                      <div
                        v-if="fil.icon === 'mdi-play-box'"
                        class="d-flex flex-column linkText"
                        @click="openVideo(fil)"
                      >
                        <span class="black--text font-weight-bold">
                          {{ fil.name }}
                        </span>
                        <span
                          v-if="fil.size"
                          v-text="getByteSize(fil.size)"
                          class="black--text text--secondary font-weight-medium"
                          style="font-size: 0.9rem"
                        />
                      </div>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="error"
                              icon
                              absolute
                              top
                              right
                              @dblclick="deleteFile(fil.id, teamDetails.id)"
                            >
                              <v-icon color="error">mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ $t('alerta.alerta_excluir.excluir2') }}
                          </span>
                        </v-tooltip>
                      </span>
                    </v-alert>
                  </v-card>
                </v-col>
                <v-col v-if="files.length === 0" cols="12" class="mx-auto">
                  <v-img
                    src="../../assets/NotebookAstronaut.svg"
                    max-width="25vw"
                    class="mx-auto"
                  />
                  <div
                    class="text-center text-h6 text-md-h5 font-weight-bold mt-2"
                    style="color: #491670"
                  >
                    <span> {{ $t('alerta.alerta_material') }} </span>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                absolute
                style="bottom: 16px; right: 16px"
                color="darkpink"
                dark
                fab
                @click="openNewFile()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-text>
          
          </v-tab-item>
          
          <!--FORUM-->
          <v-tab-item value="5">
            <v-card-text
              v-if="students.length !== 0"
              class="my-2"
              style="height: 75vh; overflow-y: scroll"
            >
              <TeacherForum :teamId="formTeam.id" :courseId="formTeam.courseId" :dialog="dialog" />
            </v-card-text>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <span class="font-weight-bold">
            {{ videoData.name }}
          </span>
          <span
            v-text="`(${getByteSize(videoData.size)})`"
            class="font-weight-medium ml-2"
            style="font-size: 0.9rem"
          />
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="mb-4">
            <span class="black--text"> {{ $t('homework.homework_detalhes.detalhes3') }} </span>
            {{ videoData.description }}
          </div>
          <div class="d-flex justify-center align-center">
            <vimeo-player
              ref="player"
              :video-url="`https://player.vimeo.com/video/${videoData.uri}`"
              @ready="onReady"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="newFile"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text"> {{ $t('botoes.botao_arquivo') }} </v-card-title>
        <v-card-text class="pa-4 pa-md-6">
          <v-form ref="newFileForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="file.name"
                  :rules="[rules.required]"
                  :label="$t('label.label_nome')"
                  color="darkblue"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :label="$t('label.label_descricao')"
                  v-model="file.description"
                  color="darkblue"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="selectType"
                  color="darkblue"
                  :label="$t('label.label_tipo')"
                  item-text="value"
                  item-value="id"
                  v-model="type"
                  item-color="darkblue"
                />
              </v-col>

              <v-col cols="12" sm="6" v-if="type == 1">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="file.link"
                  :label="$t('label.label_link')"
                  color="darkblue"
                  prepend-icon="mdi-link"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="type == 2">
                <template>
                  <v-file-input
                    :rules="[rules.required]"
                    :label="$t('label.label_arquivos.arquivo')"
                    color="darkblue"
                    v-model="file.file"
                  ></v-file-input>
                </template>
              </v-col>
              <v-col cols="12" sm="6" v-if="type == 3">
                <template>
                  <v-file-input
                    :rules="[rules.required]"
                    :label="$t('label.label_video')"
                    color="darkblue"
                    accept="video/*"
                    persistent-hint
                    :hint="$t('teams.team_hint')"
                    v-model="file.video"
                  ></v-file-input>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn
            rounded
            text
            color="darkpink"
            class="px-4"
            @click="closeNewFile()"
            :disabled="loadingFile"
          >
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            rounded
            dark
            color="darkpink"
            class="px-4"
            @click="saveNewFile(teamDetails.all, file)"
            :loading="loadingFile"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <span class="font-weight-bold">
            {{ videoData.name }}
          </span>
          <span
            v-text="`(${getByteSize(videoData.size)})`"
            class="font-weight-medium ml-2"
            style="font-size: 0.9rem"
          />
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="mb-4">
            <span class="black--text"> {{ $t('homework.homework_detalhes.detalhes3') }} </span>
            {{ videoData.description }}
          </div>
          <div class="d-flex justify-center align-center">
            <vimeo-player
              ref="player"
              :video-url="`https://player.vimeo.com/video/${videoData.uri}`"
              @ready="onReady"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="resetarData" max-width="550px">
      <v-card
        class="rounded-lg py-3 warning--text"
        style="border-left: 12px inset orange"
      >
        <v-card-title>
          <v-icon left large color="warning">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_dataAp') }}
        </v-card-text>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" :sm="editMode ? 12 : 6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          :label="$t('label.label_dataAplicacao')"
                          append-icon="mdi-calendar"
                          v-model="datenew"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="datenew"
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>*{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="warning"
            dark
            rounded
            class="px-3"
            @click="DateEdit()"
          >
            {{ $t('botoes.botao_trocar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="error"
        left
        >mdi-close-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
    
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="confirmDeletePhoto" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t('alerta.alerta_excluir.excluir3') }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDeletePhoto = false"
            >{{ $t('botoes.botao_cancelar') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="DeletePhotosFileChange()"
            >{{ $t('botoes.botao_continuar') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="photosDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ $t('teams.team_upload.label') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="photosDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-file-input
            v-model="selectedFiles"
            multiple
            show-size
            @change="handleAppliedClassPhotosFileChange"
            :label="$t('label.label_enviarfoto')"
            :placeholder="$t('teams.team_upload.placeholder')"
          ></v-file-input>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="uploadAppliedClassPhotos">{{ $t('botoes.botao_enviar') }}</v-btn>
          <v-spacer></v-spacer>
          <div class="loaded-photos-container">
            <v-subheader>{{ $t('teams.team_fotos') }}</v-subheader>
            <!-- Carrossel de fotos começa aqui -->
            <v-carousel
              cycle
              height="400"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(photo, index) in photosLoaded"
                :key="index"
              >
                <v-img
                
                  :src="photo"
                  height="100%"
                >
                <v-btn color="red" icon @click="ConfirmDeletePhotosFileChange(photo)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <!-- Carrossel de fotos termina aqui -->
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="photosDialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>

      
    </v-dialog>

    <!-- Modal/Diálogo -->
    <v-dialog v-model="qrCodeDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ $t('teams.team_qrcode') }}
          <v-spacer></v-spacer>
          <!-- Botão para fechar o modal -->
          <v-btn icon @click="qrCodeDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Container do Leitor de QR Code -->
          <div id="reader" style="width: 500px; height: 500px;"></div>
        </v-card-text>
      </v-card>
    </v-dialog>  
    
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          left
          fab
          absolute
          large
          color="darkcolor"
          class="elevation-8"
          style="bottom: 30px; right: 30px"
          v-bind="attrs"
          v-on="on"
          @click="returnMenu()"
        >
          <v-icon color="white">mdi-drag</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('botoes.botao_menurapido') }}</span>
    </v-tooltip> -->
    
    <v-dialog
        v-model="dialogMenu"
        persistent
        :width="$vuetify.breakpoint.mdAndUp ? '39%' : '71%%'"
      >
        <v-card class="rounded-lg">
          <v-card-title
            class="
              darkblue
              white--text
              px-4
              d-flex
              justify-space-between
              align-center
            "
          >
            <span>{{ $t('botoes.botao_menurapido') }}</span>
            <v-btn dark icon @click="closeRegister()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="px-2 px-sm-4 px-md-6 px-lg-10 py-4 align-cent" 
            style="
              align-content: flex-end;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
              justify-content: center;"
          >
              <template>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(1)"
                >
                  <v-icon small class="mr-1">mdi-bookshelf</v-icon>
                  {{ $t('teams.team_atalho.atalho1') }}
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(2)"
                >
                  <v-icon small class="mr-1">mdi-account-school</v-icon>
                  {{ $t('teams.team_atalho.atalho2') }}
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(3)"
                >
                  <v-icon small class="mr-1">mdi-file-tree</v-icon>
                  {{ $t('teams.team_atalho.atalho3') }}
                </v-btn>                
              </template>
              <br><br>
              <template>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(4)"
                >
                  <v-icon small class="mr-1">mdi-account-hard-hat</v-icon>
                  {{ $t('teams.team_atalho.atalho4') }}
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(5)"
                >
                  <v-icon small class="mr-1">mdi-calendar-star</v-icon>
                  {{ $t('teams.team_atalho.atalho5') }}
                </v-btn>
                <v-btn
                  rounded
                  outlined
                  small
                  color="blue"
                  class="px-2 mx-1"
                  @click="Atalho(6)"
                >
                  <v-icon small class="mr-1">mdi-certificate</v-icon>
                  {{ $t('teams.team_atalho.atalho6') }}
                </v-btn>                
              </template>              
          </v-card-text>
        </v-card>
    </v-dialog>

    <!-- DIÁLOGO DE CORREÇÃO DE LIÇÕES DE CASA -->
    <v-dialog v-model="homeworkDialog" persistent width="70%">
      <v-card class="rounded-lg">
        <v-card-title class="font-weight-medium text-subtitle-1 darkblue white--text">
          {{ $t('hometeacher.tarefas_enviadas') }}
          <v-spacer />
          <v-btn dark icon @click="homeworkDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <!-- Se o arquivo de descrição já foi enviado, exibir o link -->
        <v-card-text class="px-4 py-4">
          <div v-if="selectedAppliedClass.homeworkurl != null">
            <p>{{ $t('hometeacher.arquivo_descricao_enviado') }}</p>
            <a :href="selectedAppliedClass.homeworkurl" target="_blank" class="font-weight-medium">
              {{ $t('hometeacher.ver_arquivo') }}
            </a>
          </div>

          <!-- Caso contrário, exibir o campo para upload -->
          <div style="margin-top: 1em;">
            <v-file-input
              v-model="taskDescriptionFile"
              accept=".pdf,.doc,.docx,.txt"
              :label="$t('hometeacher.subir_descricao_tarefa')"
              prepend-icon="mdi-upload"
              outlined
              dense
            ></v-file-input>

            <v-btn
              color="darkpink"
              dark
              rounded
              class="mt-4"
              @click="uploadTaskDescription"
              :disabled="!taskDescriptionFile"
            >
              <v-icon left>mdi-upload</v-icon>
              {{ $t('botoes.botao_enviar') }}
            </v-btn>
          </div>
        </v-card-text>

        <!-- Lista de Arquivos Enviados pelos Alunos -->
        <v-card-title class="font-weight-medium mt-6 text-subtitle-1">
          {{ $t('hometeacher.lista_atividades') }}
        </v-card-title>

        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $t('hometeacher.coluna_aluno') }}</th>
                <th>{{ $t('homework.coluna_arquivo') }}</th>
                <th>{{ $t('homework.coluna_enviado') }}</th>
                <th>{{ $t('hometeacher.coluna_corrigir') }}</th>
                <th>{{ $t('homework.coluna_nota') }}</th>
                <th>{{ $t('homework.coluna_data_corrigido') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(task, index) in homeworkTasks" :key="index">
                <!-- Nome do Aluno -->
                <td>{{ task.student.name }}</td>
                
                <!-- Link para o Arquivo -->
                <td><a :href="task.url" target="_blank">{{ task.filename }}</a></td>
                
                <!-- Data de Envio -->
                <td>{{ formatarData(task.createdAt) }}</td>
                
                <!-- Campo para Inserir Nota -->
                <td>
                  <v-text-field
                    v-model="task.grade"
                    type="number"
                    min="0"
                    max="10"
                    :label="$t('hometeacher.insira_nota')"
                    outlined
                    dense
                  ></v-text-field>
                </td>

                <!-- Nota Atual -->
                <td>{{ task.note || '-' }}</td>

                <!-- Data de Correção -->
                <td>{{ task.updatedAt ? formatarData(task.updatedAt) : '-' }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <!-- Botão para Salvar Notas -->
          <v-btn
            color="darkpink"
            dark
            rounded
            class="mt-4"
            @click="saveGrades"
            :disabled="!canSaveGrades"
          >
            <v-icon left>mdi-content-save</v-icon>
            {{ $t('botoes.salvar_notas') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import TeacherForum from "../Forum/ForumTopics.vue";

const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data() {
    return {
      successSnackbar: false,
      errorSnackbar: false,
      selectType: [
        { id: 1, value: this.$t('teams.team_select.select1') },
        { id: 2, value: this.$t('teams.team_select.select2') },
        { id: 3, value: this.$t('teams.team_select.select3') },
      ],
      newFile: false,
      file: [],
      files: [],
      course: [],
      folders: [],
      filesfolder: [],
      type: 1,
      classTab: 1,

      dialog: null,
      dialogMenu: null,
      formTeam: {},
      search: "",
      headersfiles: [
        { text: 'Nome', value: 'name' },
      ],
      headersClass: [
        { text: this.$t('teams.team_headers.header1'), value: "title" },
        { text: this.$t('teams.team_headers.header2'), value: "applied", sortable: false },
        {
          text: this.$t('teams.team_headers.header3'),
          value: this.$t('teams.team_headers.header4'),
          sortable: false,
        },
      ],
      folderId: null,
      loadingVisible: false,
      loadingApplied: false,
      applyStatus: true,
      visibleStatus: true,
      deletephoto: '',
      confirmDeletePhoto: false,
      aplicado: "",
      Teams: [],
      menu: false,
      editedIndex: -1,
      dialogDelete: null,
      loadingFile: false,
      editMode: false,
      resetarData: false,
      datenew: '',
      informacaoClassID: '',
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      classId: null,
      classIdTeacher: null,
      classes: [],
      students: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
      Course: [],
      teamDetails: { id: "", thumb: "", team: "", course: "", teacher: "" },
      videoDialog: false,
      videoData: [],

      photosDialog: false,
      selectedFiles: [],
      photosLoaded: [],

      qrCodeDialog: false,

      homeworkDialog: false,
      homeworkTasks: [],
      taskDescriptionFile: null,
      selectedAppliedClass: {
        homeworkurl: null,
      }, 
    };
  },

  computed: {
    canSaveGrades() {
      // Verifica se há tarefas com notas para salvar
      return this.homeworkTasks.some(task => task.grade);
    }
  },

  methods: {
    returnMenu() {
      this.dialogMenu = true
    },
    // Atalho(id){
    //   switch (id) {
    //     case 1:
    //       this.$parent.$parent.$parent.page =  this.$t('teams.team_atalho.atalho1')
    //       break;          
    //     case 2:
    //       this.$parent.$parent.$parent.page = this.$t('teams.team_atalho.atalho2')
    //       break;          
    //     case 3:
    //       this.$parent.$parent.$parent.page = this.$t('teams.team_atalho.atalho3')
    //       break;        
    //     case 4:
    //       this.$parent.$parent.$parent.page = this.$t('teams.team_atalho.atalho4')
    //       break;
    //     case 5:
    //       this.$parent.$parent.$parent.page = this.$t('teams.team_atalho.atalho5')
    //       break;
    //     case 6:
    //       this.$parent.$parent.$parent.page = this.$t('teams.team_atalho.atalho6')
    //       break;                    
    //   }
    // },

    teacherTraining(){
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu5')
      this.$router.push("/teacherFuture/Training")
    },

    async saveNewFile(details, data) {
      let validation = this.$refs.newFileForm.validate();

      if (validation) {
        this.loadingFile = true;

        try {
          if (this.type === 1) {
            let body = {
              name: data.name,
              type: ".link",
              description: data.description,
              uri: data.link,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }

          if (this.type === 2) {
            let res = await this.registerFile(data.file);
            //console.log("Res", res);

            let body = {
              name: data.name,
              type: res.type,
              size: res.size,
              description: data.description,
              uri: res.uri,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }

          if (this.type === 3) {
            let res = await this.registerVideo(data.video);
            //console.log("Res", res);

            let body = {
              name: data.name,
              type: res.type,
              size: res.size,
              description: data.description,
              uri: res.uri,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }
        } catch (error) {
          this.loadingFile = false;
          this.errorSnackbar = true;
          console.error(error);
        }
      }
    },
    async registerVideo(video) {
      let formData = new FormData();
      formData.append("video", video, video.name);

      let response = await axios.post(`${url}/upload-video/vimeo`, formData);
      //console.log(response);
      let link = response.data.videoId;

      let elem = video.name.split(".");
      let type = "." + elem[1];

      let fileData = {
        type: type,
        size: video.size,
        uri: link,
      };

      return fileData;
    },
    async registerFile(file) {
      let formData = new FormData();
      formData.append("file", file, file.name);

      let response = await axios.post(`${url}/files/upload`, formData);
      let link = response.data.url;

      let elem = file.name.split(".");
      let type = "." + elem[1];

      let fileData = {
        type: type,
        size: file.size,
        uri: link,
      };

      return fileData;
    },
    async saveFile(body) {
      await axios.post(`${url}/teacherTeamFiles`, body, authorization);
      this.successSnackbar = true;
      this.$refs.newFileForm.reset();
      this.getFiles(body.teamId);
      this.newFile = false;
      this.loadingFile = false;
    },
    async getFiles(teamId) {
      let files = await axios.get(
        `${url}/teacherTeamFiles/byTeacherTeam/${teamId}`,
        authorization
      );
      //console.log(files)
      this.files = files.data;
      this.newFile = false;
      this.loadingFile = false;
      this.getType();
    },

    async getTeacherPhoto(teamId) {
      let files = await axios.get(
        `${url}/teacherTeamFiles/byTeacherTeam/${teamId}`,
        authorization
      );
      //console.log(files)
      this.files = files.data;
      this.newFile = false;
      this.loadingFile = false;
      this.getType();
    },
    
    deleteFile(id, teamId) {
      axios.delete(`${url}/teacherTeamFiles/${id}`, authorization);

      this.getFiles(teamId);
    },
    openNewFile() {
      this.newFile = true;
    },
    closeNewFile() {
      this.$refs.newFileForm.reset();
      this.newFile = false;
    },
    getByteSize(byte_value) {
      let converted_value = null;
      let data_type = "";
      if (byte_value < 1024) {
        data_type = "bytes";
        converted_value = byte_value;
      } else if (byte_value < 1024 * 1024) {
        data_type = "KB";
        converted_value = (byte_value / 1024).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024) {
        data_type = "MB";
        converted_value = (byte_value / (1024 * 1024)).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024 * 1024) {
        data_type = "GB";
        converted_value = (byte_value / (1024 * 1024 * 1024)).toFixed(2);
      } else {
        data_type = "TB";
        converted_value = (byte_value / (1024 * 1024 * 1024 * 1024)).toFixed(2);
      }
      let response = converted_value + " " + data_type;
      return response;
    },
    getType() {
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];

        switch (element.type) {
          case ".pdf":
            element.icon = "mdi-file-pdf-box";
            break;
          case ".docx":
          case ".docm":
          case ".dotx":
          case ".dotm":
          case ".txt":
            element.icon = "mdi-file-word-box";
            break;
          case ".xlsx":
          case ".xlsm":
          case ".xltx":
          case ".xltm":
          case ".xlsb":
          case ".xlam":
            element.icon = "mdi-file-excel-box";
            break;
          case ".pptx":
          case ".pptm":
          case ".potx":
          case ".potm":
          case ".ppam":
          case ".ppsx":
          case ".ppsm":
          case ".sldx":
          case ".sldm":
          case ".thmx":
            element.icon = "mdi-file-powerpoint-box";
            break;
          case ".mp4":
          case ".mov":
          case ".wmv":
          case ".avi":
          case ".avchd":
          case ".flv":
          case ".f4v ":
          case ".swf":
          case ".mkv":
          case ".webm":
          case ".html5":
          case ".mpeg-2":
            element.icon = "mdi-play-box";
            break;
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".bmp":
          case ".psd":
          case ".tiff":
          case ".svg":
          case ".raw":
          case ".webp":
            element.icon = "mdi-image";
            break;
          case ".zip":
          case ".arj":
          case ".cab":
          case ".rar":
          case ".tar":
          case ".z":
          case ".gz":
          case ".taz ":
          case ".tgz":
          case ".gzip":
            element.icon = "mdi-zip-box";
            break;
          case ".link":
            element.icon = "mdi-link";
            break;
          default:
            element.icon = "mdi-file";
            break;
        }
      }
    },
    changeApplied(lesseon) {
      const timeElapsed = Date.now();
      const today = new Date(timeElapsed);
      const data = today.toLocaleDateString()
      const dateUS = data.split('/').reverse().join('-')

      lesseon.DateApplied = dateUS;
      this.updateClass(lesseon);
    },

    changeVisual(lesseon) {
      if(lesseon.DateApplied == null){
        lesseon.DateApplied = '01-01-2024'
      }
      this.updateClass(lesseon);
    },

    async updateClass(lesson) {
      const body = {
        id: lesson.appliedClassId, 
        classId: lesson.id,
        teacherId: this.formTeam.teacherId,
        teamId: this.formTeam.id,
        schoolId: this.formTeam.schoolId,
        applied: lesson.applied,
        visible: lesson.visible,
        DateApplied: lesson.DateApplied,
        homework: lesson.homework|| false, 
        homeworkurl: lesson.homeworkurl,
      };
      
      console.log(body);
      if (body.id == null) {
        await axios.post(`${url}/appliedClasses`, body, authorization);
      }
      else {
        await axios.put(`${url}/appliedClasses/${body.id}`, body, authorization);
      }
      
      const Class = {
        teacherId: this.formTeam.teacherId,
        teamId: this.formTeam.id,
        schoolId: this.formTeam.schoolId,
        courseId: this.formTeam.courseId,
      };
      this.getClassDetails(Class)
    },

    save(date) {
      this.$refs.menu.save(date);
    },

    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getTeam() {
      axios
        .get(`${url}/teamsForTeacher`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Teams = res.data;
          //console.log(this.Teams)
        })
        .catch((error) => {
          console.error(error);
        });
    },

    editdate(item){
      this.informacaoClassID = item.id,
      //console.log(this.informacaoClassID)
      this.resetarData = true  
      this.editMode = true
    },

    DateEdit(){
      const body = {
        classId: this.informacaoClassID,
        teacherId: this.formTeam.teacherId,
        teamId: this.formTeam.id,
        schoolId: this.formTeam.schoolId,
        DateApplied: this.datenew,
      }
      //console.log(body)
      this.applyDate(body)
      this.datenew = ''
      this.resetarData = false  
      this.editMode = false
    },

    async applyDate(body) {
      await axios.put(`${url}/appliedClassesDate`, body, {
        headers: {
          authorization: token,
        },
      });

      const Class = {
        teacherId: this.formTeam.teacherId,
        teamId: this.formTeam.id,
        schoolId: this.formTeam.schoolId,
        courseId: this.formTeam.courseId,
      };
      this.getClassDetails(Class)
    },

    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
    closeRegister() {
      this.Course = [];
      this.dialog = false;
      this.editMode = false;
      this.dialogMenu = false;
      this.$refs.teamsForm.reset();
    },

    checkVisible() {
      if (this.visibleStatus) {
        this.unvisibleAll();
      } else {
        this.visibleAll();
      }
    },
    async visibleAll() {
      this.loadingVisible = true;

      for (let index = 0; index < this.classes.length; index++) {
        const element = this.classes[index];
        element.visible = true;

        await this.updateClass(element);
      }

      this.loadingVisible = false;
      this.visibleStatus = true;
    },

    async unvisibleAll() {
      this.loadingVisible = true;

      for (let index = 0; index < this.classes.length; index++) {
        const element = this.classes[index];
        element.visible = false;

        await this.updateClass(element);
      }

      this.loadingVisible = false;
      this.visibleStatus = false;
    },

    checkApply() {
      if (this.applyStatus) {
        this.unapplyAll();
      } else {
        this.applyAll();
      }
    },
    async applyAll() {
      this.loadingApplied = true;

      for (let index = 0; index < this.classes.length; index++) {
        const element = this.classes[index];
        element.applied = true;

        await this.updateClass(element);
      }

      this.loadingApplied = false;
      this.applyStatus = true;
    },
    openURL(url) {
      window.open(url);
    },
    openVideo(link) {
      this.videoDialog = true;

      this.videoData = link;
    },
    async unapplyAll() {
      this.loadingApplied = true;

      for (let index = 0; index < this.classes.length; index++) {
        const element = this.classes[index];
        element.applied = false;

        await this.updateClass(element);
      }

      this.loadingApplied = false;
      this.applyStatus = false;
    },
    onReady() {
      this.playerReady = true;
    },

    getClasses(item) {
      this.applyStatus = true;
      this.visibleStatus = true;
      this.Course = item.Course;
      this.Course.size = item.Course.courseAssemblies.length;
      this.editedIndex = item.id;
      this.formTeam = Object.assign({}, item);
      const body = {
        teacherId: this.formTeam.teacherId,
        teamId: this.formTeam.id,
        schoolId: this.formTeam.schoolId,
        courseId: this.formTeam.courseId,
      };
      this.getClassDetails(body);

      this.dialog = true;
      this.editMode = true;
    },
    getDetails(data) {
      this.teamDetails.id = data.id;
      this.teamDetails.thumb = data.Course.thumbnail;
      this.teamDetails.team = data.name;
      this.teamDetails.course = data.Course.name;
      this.teamDetails.teacher = data.teacher.name;
      this.teamDetails.all = data;

      this.getClasses(data);
      this.getStudents(data.id);
      this.getFiles(data.id);
      this.getTeacherPhoto(data.id)
      this.getCorserFolder(data.courseId)

      this.classTab = 1;
      this.dialog = true;
    },
    async getStudents(id) {
      let res = await axios.get(`${url}/student/team/${id}`);

      this.students = res.data.result;
    },

    async getCorserFolder(Teams){
      let res = await axios.get(
        `${url}/folderAssemblies/testando/${Teams}`,
          authorization,
      );
      this.course = res.data
      for (let y = 0; y < this.course.length; y++) {
        this.folders[y] = this.course[y].fk_folder
      }
      this.getFilesFolder()
    },

    async getFilesFolder() {
      for (let x = 0; x < this.folders.length; x++) {
        let files = await axios.get(
          `${url}/trainingFiles/folder/${this.folders[x]}`,
        authorization
      )
      if(x == 0){
          this.filesfolder = files.data;
        }
      else{
          let num = this.filesfolder.length
          for (let y = 0; y < files.data.length; y++) {
            this.filesfolder[num] = files.data[y]
            num += 1
          }
        }
      }
      this.getType();
    },
    openLink(url){
      window.open(url);
    },

    getClassDetails(body) {
      axios
        .post(`${url}/appliedClasses/applied`, body, authorization)
        .then((res) => {
          this.classes = res.data;
          for (let index = 0; index < this.classes.length; index++) {
            const element = this.classes[index];
            if (!element.applied) this.applyStatus = false;

            if (!element.visible) this.visibleStatus = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },


    openPhotosUploadDialog(data) {
      this.classId = data.id;
      this.photosDialog = true;
      this.fetchLoadedPhotos();
    }, 
    ConfirmDeletePhotosFileChange(data) {
      this.deletephoto = data.split('https://newmoodle.s3.amazonaws.com/')
      this.confirmDeletePhoto = true
    },
    DeletePhotosFileChange() {
      //console.log(this.deletephoto[1])
      let body = {
        file: this.deletephoto[1]
      }
      const response = axios.post(
          `${url}/DeleteClasses/files`, body,
          authorization
        ); // Ajuste o URL conforme necessário 
      console.log(response)
      setTimeout(() => {
          this.fetchLoadedPhotos();
          this.confirmDeletePhoto = false
          this.successSnackbar = true;
      }, 500);
    },    
    handleAppliedClassPhotosFileChange() {
      // Este método é chamado quando os arquivos são selecionados ou alterados
      // Você pode implementar lógica adicional aqui, se necessário
    },
    async uploadAppliedClassPhotos() {
      let formData = new FormData();

      // Se múltiplos arquivos forem permitidos, você precisará iterar sobre eles
      this.selectedFiles.forEach((file) => {
        formData.append("files", file); // Use "files" se estiver enviando múltiplos arquivos
      });

      formData.append("classId", String(this.classId));
      formData.append("teamId", String(this.formTeam.id));

      try {
        const response = await axios.post(`${url}/appliedClasses/files`, formData, {});
        let link = response.data.url;
        console.log(link);
        this.successSnackbar = true;
        this.selectedFiles = [];
        this.fetchLoadedPhotos();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchLoadedPhotos() {
      try {
        const response = await axios.get(
          `${url}/appliedClasses/loadPhotos/${this.classId}/${this.formTeam.id}`,
          authorization
        ); // Ajuste o URL conforme necessário
        console.log(response);
        this.photosLoaded = response.data.urls; // Ajuste conforme a estrutura da sua resposta
      } catch (error) {
        console.error(error);
      }
    },

    openQrReader() {
      console.log("Abrindo leitor de QR Code...");

      setTimeout(() => {
        const html5QrCode = new Html5Qrcode("reader");
        const config = { fps: 10, qrbox: 250 };
        const onScanSuccess = (decodedText, decodedResult) => {
          console.log(`Código QR lido: ${decodedText}`, decodedResult);
          let studentId = parseInt(decodedText.split('=')[1]);
          this.addStutendIntoTeam(studentId);
          html5QrCode.stop().then(() => {
            console.log("Leitor de QR Code parado.");
            this.qrCodeDialog = false; // Isso deve funcionar, pois estamos em uma arrow function
          }).catch((err) => {
            console.error("Erro ao parar o leitor de QR Code:", err);
          });
        };

        html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess).catch((err) => {
          console.error("Erro ao iniciar o leitor de QR Code:", err);
          this.qrCodeDialog = false;
        });
      }, 500);
    },
    stopQrReader() {
      // Parar o leitor de QR Code, se necessário
      //Html5Qrcode.shimStopStream();
      console.log("Leitor de QR Code parado.");
    },
    async addStutendIntoTeam(studentId) {
      let res = await axios.get(
        `${url}/addStudentIntoTeam/${studentId}/${this.formTeam.id}`,
        authorization
      );

      let success = res.data.success;
      if (success) {
        this.successSnackbar = true;
        this.getStudents(this.teamDetails.id);
      }
      else {
        this.errorSnackbar = true;
      }
    },

    changeHomework(lesseon) {
      this.updateClass(lesseon);
    },
    formatarData(data) {
      const d = new Date(data);
      const dia = String(d.getDate()).padStart(2, '0');
      const mes = String(d.getMonth() + 1).padStart(2, '0');
      const ano = d.getFullYear();
      return `${dia}/${mes}/${ano}`;
    },
    async openHomeworkDialog(data) {
      this.selectedAppliedClass = data;
      const homework = await axios.get(`${url}/homework?id=${data.appliedClassId}`, authorization)
      this.homeworkTasks = homework.data;
      this.homeworkDialog = true;
    },
    async saveGrades() {
      // Lógica para salvar as notas atribuídas
      this.homeworkTasks.forEach(task => {
        if (task.grade) {
          // Enviar a nota do task para o servidor, por exemplo
          task.note = task.grade;
          task.fixed = true;
          axios.put(`${url}/homework/${task.id}`, task, authorization);
        }
      });
    },
    async uploadTaskDescription() {
      if (this.taskDescriptionFile) {
        let formData = new FormData();
        formData.append("file", this.taskDescriptionFile, this.taskDescriptionFile.name);
        const resUpload = await axios.post(`${url}/appliedClasses/homework`, formData);
        let link = resUpload.data.url;

        this.selectedAppliedClass.homeworkurl = link;
        this.updateClass(this.selectedAppliedClass);
        
        this.taskDescriptionFile = null;
      }
    },

  },
  watch: {
    qrCodeDialog(newValue) {
      if (newValue) {
        this.openQrReader();
      } else {
        this.stopQrReader();
      }
    }
  },
  components: {
    VueQrcode, 
    TeacherForum
  },

  created() {
    this.getTeam();
    this.getSchools();
  },
};
</script>

<style scoped>
.rotation {
  position: relative;
  right: 170%;
  width: 258%;
  top: 40px;
  font-size: 117%;
  line-height: 175%;
  padding-top: 2px;
  border-radius: 14px;
}

.v-speed-dial {
  position: absolute;
  right: 1%;
}
.v-sheet.v-card {
  border-radius: 14px;
}

.pointer {
  cursor: pointer;
}

.linkText:hover {
  text-decoration: black underline;
  cursor: pointer;
}
</style>
