<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-btn rounded color="darkpink" text @click="toTraining()">
        <v-icon small left>mdi-arrow-left</v-icon> {{ $t('botoes.botao_voltar') }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-row class="ma-6">
      <v-col cols="6" sm="4" md="3" lg="2" v-for="(file, i) in files" :key="i">
        <v-card
          :href="file.url"
          target="_blank"
          class="rounded-xl d-flex flex-column justify-space-between"
          elevation="4"
          height="300"
        >
          <v-card-title class="py-6">
            <v-icon x-large :color="file.color">{{ file.icon }}</v-icon>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text class="mt-12">
            <div class="text-subtitle-2 text-sm-h6 black--text">
              {{ file.name }}
            </div>
          </v-card-text>
          <v-card-actions class="grey lighten-3 pa-4 text-caption">
            <span v-text="getByteSize(file.size)"></span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data: () => ({
    files: [],
    folderId: null,
  }),
  created() {
    this.folderId = this.$parent.$parent.$parent.trainingId;
    this.getFiles();
  },
  methods: {
    async getFiles() {
      let files = await axios.get(
        `${url}/trainingFiles/folder/${this.folderId}`,
        authorization
      );
      this.files = files.data;

      this.getType();
    },
    getType() {
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];

        switch (element.type) {
          case ".pdf":
            element.icon = "mdi-file-pdf-box";
            element.color = "#A60000";
            break;
          case ".docx":
          case ".docm":
          case ".dotx":
          case ".dotm":
          case ".txt":
            element.icon = "mdi-file-word-box";
            element.color = "#0F3C8A";
            break;
          case ".xlsx":
          case ".xlsm":
          case ".xltx":
          case ".xltm":
          case ".xlsb":
          case ".xlam":
            element.icon = "mdi-file-excel-box";
            element.color = "#1E6C41";
            break;
          case ".pptx":
          case ".pptm":
          case ".potx":
          case ".potm":
          case ".ppam":
          case ".ppsx":
          case ".ppsm":
          case ".sldx":
          case ".sldm":
          case ".thmx":
            element.icon = "mdi-file-powerpoint-box";
            element.color = "#B43416";
            break;
          case ".mp4":
          case ".mov":
          case ".wmv":
          case ".avi":
          case ".avchd":
          case ".flv":
          case ".f4v ":
          case ".swf":
          case ".mkv":
          case ".webm":
          case ".html5":
          case ".mpeg-2":
            element.icon = "mdi-play-box";
            element.color = "#C31E1D";
            break;
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".bmp":
          case ".psd":
          case ".tiff":
          case ".svg":
          case ".raw":
          case ".webp":
            element.icon = "mdi-image";
            element.color = "#0B9E4C";
            break;
          case ".zip":
          case ".arj":
          case ".cab":
          case ".rar":
          case ".tar":
          case ".z":
          case ".gz":
          case ".taz ":
          case ".tgz":
          case ".gzip":
            element.icon = " mdi-zip-box";
            element.color = "#754668";
            break;
          default:
            element.icon = "mdi-file";
            element.color = "darkblue";
            break;
        }
      }
    },
    toTraining() {
      this.$router.push("/teacherFuture/Training")
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu5');
    },
    getByteSize(byte_value) {
      let converted_value = null;
      let data_type = "";
      if (byte_value < 1024) {
        data_type = "bytes";
        converted_value = byte_value;
      } else if (byte_value < 1024 * 1024) {
        data_type = "KB";
        converted_value = (byte_value / 1024).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024) {
        data_type = "MB";
        converted_value = (byte_value / (1024 * 1024)).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024 * 1024) {
        data_type = "GB";
        converted_value = (byte_value / (1024 * 1024 * 1024)).toFixed(2);
      } else {
        data_type = "TB";
        converted_value = (byte_value / (1024 * 1024 * 1024 * 1024)).toFixed(2);
      }
      let response = converted_value + " " + data_type;
      return response;
    },
  },
};
</script>

<style></style>
